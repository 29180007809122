import React from "react";

const ColorSwitcher = () => {
    const handleClick = () => {
        const rootStyles = document.documentElement.style;


        const currentTextColor = rootStyles.getPropertyValue('--textColor');
        const currentBackgroundColor = rootStyles.getPropertyValue('--backgroundColor');


        const newTextColor = currentTextColor === '#ff87c3' ? 'white' : '#ff87c3';
        const newBackgroundColor = currentBackgroundColor === 'white' ? '#ff87c3' : 'white';


        rootStyles.setProperty('--textColor', newTextColor);
        rootStyles.setProperty('--backgroundColor', newBackgroundColor);

        document.querySelector('.color-switcher').textContent = newTextColor;

    };

    return (
        <div className="color-switcher" onClick={handleClick}>#ff87c3</div>
    )
};


export default ColorSwitcher;